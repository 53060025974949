<template>
    <b-overlay :show="loading">
      <print-modal @onPrint="printMutasi"></print-modal>
        <b-card
        >
          <b-row class="align-items-center">
            <b-col md="4" sm="4" class="my-1">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                @click.prevent="add"
                variant="primary"
                v-if="allowCreate()"
              >
                <feather-icon icon="PlusIcon" class="mr-50" />
                Tambah
              </b-button>
            </b-col>
            <b-col sm="12" md="8" class="text-sm-left text-md-right">
              <b-button @click.prevent="openPrintModal" variant="outline-primary">
                <feather-icon class="mr-50" icon="PrinterIcon"></feather-icon>
                Cetak Mutasi
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6" class="my-1">
              <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50">Per page</label>
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                  class="w-50"
                />
              </b-form-group>
            </b-col>
            <b-col md="6" class="my-1">
              <b-form-group
                label="Filter"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
      
            <b-col cols="12">
              <b-table
                striped
                small
                hover
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :items="asset_mutations"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                
              >
                <template #cell(no)="row">
                  <strong class="text-center">
                    {{ (row.index + 1) }}
                  </strong>
                </template>
    
                <template #cell(tujuan)="{item}">
                  <strong v-if="item.tujuan_gudang">{{ item.tujuan_gudang.nama_gudang }}</strong>
                  <i class="text-danger" v-else>Tujuan tidak ditemukan</i>
                </template>
                <template #cell(nama)="{item}">
                  <strong v-if="item.asset">{{ item.asset.nama }}</strong>
                  <i class="text-danger" v-else>Aset tidak ditemukan</i>
                </template>
                <template #cell(satuan)="{item}">{{item.asset && item.asset.satuan ? item.asset.satuan.satuan : '-'}}</template>
                <template #cell(terima)="{item}">
                    <b-badge v-if="item.terima == 1" variant="light-success">
                        Sudah diterima
                    </b-badge>
                    <b-badge v-else variant="light-danger">
                        Belum diterima
                    </b-badge>
                </template>
      
                <template #cell(actions)="{item}">
                    <b-button variant="outline-info" class="mr-1" size="sm" @click.prevent="$router.push(`/asset-mutasi-request/detail/${item.id}`)">
                        <feather-icon icon="EyeIcon" />
                    </b-button>
                      <b-button
                        v-if="allowUpdate() && item.terima == 0"
                        v-b-tooltip.hover.right="'Ubah'"
                        size="sm"
                        @click="edit(item)"
                        class="mr-1"
                        variant="outline-info"
                      >
                        <feather-icon icon="EditIcon" />
                      </b-button>
                      <b-button
                        v-if="allowDelete() && item.terima == 0"
                        v-b-tooltip.hover.right="'Hapus'"
                        size="sm"
                        @click="remove(item)"
                        class="mr-1"
                        variant="outline-danger"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                </template>
              </b-table>
            </b-col>
      
            <b-col cols="12">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="center"
                size="sm"
                class="my-0"
              />
            </b-col>
          </b-row>
        </b-card>

        <modal-form @loading="toggleLoading" @submit="submit"></modal-form>
    </b-overlay>
  </template>
  
  <script>
  import PrintModal from './components/PrintModal.vue'
  import Ripple from 'vue-ripple-directive'
  import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import {
    BTable,
    BOverlay,
    VBTooltip,
    BDropdown,
    BDropdownItem,
    BAvatar,
    BBadge,
    BModal,
    BRow,
    BCard,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormCheckbox,
  } from "bootstrap-vue";
  import ModalForm from './components/Modal.vue'
  
  export default {
    components: {
      PrintModal,
        BCard,
      BCardActions,ToastificationContent,
      VBTooltip,
      BModal,
      BDropdown,
      BDropdownItem,
      BTable,
      BOverlay,
      BAvatar,
      BBadge,
      BRow,
      BCol,
      BFormGroup,
      BFormSelect,
      BPagination,
      BInputGroup,
      BFormInput,
      BInputGroupAppend,
      BButton,
      BFormCheckbox,
      ModalForm
    },
    directives: {
      "b-tooltip": VBTooltip,
      Ripple,
    },
    data() {
      return {
        loading: false,
        id:null,
        perPage: 10,
        pageOptions: [10, 30, 50],
        totalRows: 1,
        currentPage: 1,
        sortBy: "",
        sortDesc: false,
        sortDirection: "asc",
        filter: null,
        filterOn: [],
        fields: [
          {
            key: "no",
            label: "No",
          },
          { key: "tujuan", label: "Tujuan Mutasi", sortable: true },
          { key: "nama", label: "Aset", sortable: true },
          { key: "jumlah", label: "Jumlah Mutasi", sortable: true },
          { key: "satuan", label: "Satuan" },
          { key: "terima", label: "Status" },
          { key: "actions", label: "Aksi" }
        ],
        asset_mutations: [],
        form: {
            dari: null,
            tujuan: null,
            asset_id: null,
            jumlah: 0,
            penyimpanan_dari: null,
            terima: 0
        }
      };
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter((f) => f.sortable)
          .map((f) => ({ text: f.label, value: f.key }));
      },
    },
    methods: {
      async printMutasi(params) {
        try {
          this.$bvModal.hide('print-modal')
          this.loading = true
          const response = await this.$store.dispatch('asset/printMutasi', params)
          this.loading = false

          window.open(URL.createObjectURL(response))
        }
        catch(e) {
          this.$bvModal.show('print-modal')
          this.loading = false
          this.displayError(e)
          return false
        }
      },
      openPrintModal() {
        this.$bvModal.show('print-modal')
      },
      add() {
        this.$store.commit('asset/SET_CURRENT_MUTATION', null)
        this.$bvModal.show('form-mutasi-request')
      },
      toggleLoading(val) {
        this.loading = val
      },
      remove(item ){
        this.$swal({
          title: 'Anda yakin?',
          text: `Data mutasi ini akan dihapus`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Batal',
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            item.fungsi = 1 // soft delete
            this.$store.dispatch('asset/saveRequestMutation', [item])
            .then(() => {
                this.getMutationRequest()
            })
            .catch(e => {
              this.displayError(e)
              return false
            })
          }
        })
      },
      resetForm() {
        this.id = null
        delete this.form.id
        this.form.tujuan = null
        this.form.asset_id = null
        this.form.jumlah = 0
        this.form.penyimpanan_dari = null
        if(this.myGudang) {
            this.form.dari = this.myGudang.id
        }
        else {
            this.form.dari = null
        }
      },
      async submit(payload) {
        try {
            this.loading = true
            await this.$store.dispatch('asset/saveRequestMutation', [payload])
            this.loading = false
            this.displaySuccess({
                message: 'Request Mutasi berhasil disimpan'
            })
            this.$store.commit('asset/SET_CURRENT_MUTATION', null)
            this.$bvModal.hide('form-mutasi-request')
            this.getMutationRequest()
        }
        catch(e) {
            this.loading = false
            this.displayError(e)
            return false
        }
      },
      edit(item) {
        this.$store.commit('asset/SET_CURRENT_MUTATION', item)
        this.$bvModal.show('form-mutasi-request')
      },
      async getMutationRequest() {
        const params = {order: 'desc'}
        if(this.isAdminGudang || this.isAdminAsset) {
          params.dari = this.myGudang.id
        }
        this.asset_mutations = await this.$store.dispatch('asset/getRequestMutation', params)
        this.totalRows = this.asset_mutations.length
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      async getAsset() {
        await this.$store.dispatch('asset/getBarang', {order: 'desc'})
      },
      async getGudang() {
        const gudangs = await this.$store.dispatch('gudang/getData', {jenis: 1})
        let gudangTujuan = [] 
        if(this.myGudang) {
          gudangTujuan = gudangs.filter(gudang => gudang.id != this.myGudang.id)
          gudangTujuan = gudangTujuan.map(item => ({ value: item.id, text: item.nama_gudang }))
          this.$store.commit('asset/SET_GUDANG_TUJUAN', gudangTujuan)
        }

      }
    },
    async created() {
        this.loading = true
        this.getMutationRequest()
        await this.getAsset()
        await this.getGudang()
        this.loading = false
    }
  };
  </script>
  